<template>
  <div
    class="page-box box"
    @scroll="getScroll($event)"
  >
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
    >
      <div
        class="search-box"
        @click="goSearch"
      >
        输入关键词
        <div class="icon"></div>
      </div>
      <!-- banner -->
      <div class="banner-box">
        <van-swipe
          class="swiper"
          :autoplay="4500"
          @change="onChange"
        >
          <template v-for="(item, index) in bannerList">
            <van-swipe-item
              :key="index"
              class="swiper-item"
              @click="imgBtn(item)"
            >
              <img
                :src="item.img"
                alt=""
              >
            </van-swipe-item>
          </template>
          <template #indicator>
            <div class="custom-indicator box">
              <template v-for="(item, cIndex) in bannerList">
                <div
                  :key="item.bannerNo + cIndex"
                  class="swiper-indicator"
                  :class="{'active-indicator': cIndex === current}"
                >
                </div>
              </template>
            </div>
          </template>
        </van-swipe>
      </div>
      <!-- banner end -->

      <!-- 分类 -->
      <div class="type-box">
        <van-grid
          :border="false"
          :column-num="4"
        >
          <van-grid-item
            v-for="(item, index) of showClassifyList"
            :key="index"
            @click="goType(index, item)"
          >
            <div
              v-if="index <= 7"
              class="type-item"
            >
              <template v-if="index === 7">
                <!-- <img src="https://test.tjerong.cn/images/org/1626429952356.png"> -->
                <img src="https://erong-qtj.oss-cn-beijing.aliyuncs.com/icons/quanbu.png" />
                <p>全部</p>
              </template>
              <template v-else>
                <img :src="item.img" />
                <p>{{ item.classifyName }}</p>
              </template>
            </div>
          </van-grid-item>
        </van-grid>
      </div>

      <!-- <div
        class="active-img box"
      >
        <van-image :src="info.activityImg"></van-image>
      </div> -->
      <!-- 商品分类 -->
      <div class="list-box box">
        <div class="list-title">
          <div class="title-logo"></div>
          商品分类
          <div
            class="more-btn"
            @click="goType(1, {})"
          >
            更多
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="type-box box">
          <div
            v-for="(item, index) in typeArr"
            :key="item.classifyNo + index"
            class="type-item"
            @click="goType(1, item)"
          >
            <img
              :src="item.img"
              alt=""
            >
            <div class="type-text-a">
              {{ item.classifyName }}
            </div>
            <div class="type-text-b">
              更多
            </div>
          </div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="list-box box">
        <div class="list-title">
          <div class="title-logo"></div>
          优选商品
          <div
            class="more-btn"
            @click="goType(1, {})"
          >
            更多
            <van-icon name="arrow" />
          </div>
        </div>

        <div class="list-in-box box">
          <!-- https://cf-workphone.oss-cn-hangzhou.aliyuncs.com/wpUpload/88agoh4nqjn1671277520200.png -->
          <div
            v-for="(item, index) in schoolList"
            :key="item.courseNo + index"
            class="item-box box"
            @click="goSchoolDetail(item)"
          >
            <div class="img-box">
              <van-image :src="item.img || 'https://cf-workphone.oss-cn-hangzhou.aliyuncs.com/wpUpload/88agoh4nqjn1671277520200.png'">
                <template #error>
                  图片加载失败
                </template>
              </van-image>
            </div>
            <div class="text-a">
              <!-- <div class="tag-b tag">门店活动</div> -->
              <div class="text">
                {{ item.courseName }}
              </div>
              <div
                class="tag-a tag"
              >
                {{ item.subject }}
              </div>
            </div>
            <div class="price-box box">
              <div class="price-a">
                ￥{{ item.amount || 0 }}
              </div>
              <div class="price-b">
                原价￥{{ item.price || 0 }}
              </div>
            </div>
          </div>
          <div
            v-show="loading"
            class="end-text box"
          >
            加载中...
          </div>
          <div
            v-show="finished"
            class="end-text box"
          >
            没有更多了
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <tabbar :active-index="0"></tabbar>
  </div>
</template>

<script>
import tabbar from '@/components/common/tabbar.vue'
export default {
  name: 'Test',
  components: {
    tabbar,
  },
  data () {
    return {
      current: 0,
      bannerList: [],
      info: {},
      classifyList: [],
      showClassifyList: [],
      pageSize: 10,
      pageNo: 1,
      schoolList: [],
      isLoading: false,
      loading: false,
      total: 0,
      finished: false,
      typeArr: [
        {classifyName: '文化', classifyNo: '1', img: 'https://erong-qjy.oss-cn-beijing.aliyuncs.com/wechat/zi.png'},
        {classifyName: '素养', classifyNo: '2', img: 'https://erong-qjy.oss-cn-beijing.aliyuncs.com/wechat/ju.png'},
        {classifyName: '其他商品', classifyNo: '3', img: 'https://erong-qjy.oss-cn-beijing.aliyuncs.com/wechat/huang.png'}
      ],
    }
  },
  computed: {
  },
  created () {
    // var btn = document.getElementById('launch-btn');
    // btn.addEventListener('launch', function (e) {
    //   console.log('app success', e);
    // });
    // btn.addEventListener('error', function (e) {
    //   console.log('app fail', e.detail);
    // });
    this.getBannerInfo()
    this.getClassifies()
    let vm = this;
    vm.getGoodsList()
    try {
    // wx.ready(function(){
    //   // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    // });
      console.log('进入')
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          window.localStorage.setItem('latitude', latitude)
          window.localStorage.setItem('longitude', longitude)
          console.log(res, '成功')
        },
        fail (res) {
          console.log(res, '失败')
        },
        complete: function (res) {
          console.log(res, 'res')
          vm.getGoodsList()
        }
      });
    } catch (e) {
      console.log(e)
      vm.getGoodsList()
    }
  },
  methods: {
    imgBtn (item) {
      if (item.targetUrl) {
        window.open(item.targetUrl)
      }
    },
    getLocation () {
    },
    onChange(index) {
      this.current = index;
    },
    async getClassifies () {
      let res = await this.$http.homeApi.getClassifies({})
      console.log(res, '类型')
      if (res.code === 200) {
        this.typeArr = res.msg || []
      }
    },
    async getGoodsList () {
      let vm = this
      if (!this.loading) {
        this.loading = true;
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          // lat: window.localStorage.getItem('latitude') || '',
          // lng: window.localStorage.getItem('longitude') || ''
        }
        this.pageNo ++
        try {
          let res = await this.$http.homeApi.courseList(data)
          console.log(res, 'list')
          if (res.code === 200) {
            console.log(res, 'res')
            let arr = this.$deepClone(this.schoolList)
            for (let i of res.msg.data || []) {
              arr.push(i)
            }
            this.schoolList = arr
            this.total = res.msg.total
            this.finished = this.schoolList.length >= res.msg.total
          }
          this.loading = false
        } catch (e) {
          vm.loading = false
        }
      }
    },
    async getBannerInfo () {
      console.log('bannerinfo')
      let res = await this.$http.homeApi.bannerInfo({})
      console.log(res, 'res===')
      if (res.code === 200) {
        console.log(res, 'res')
        let data = res.msg || []
        // this.info = data
        // data.push(data[0])
        this.bannerList = data
        // this.classifyList = data.classifyList
        // this.showClassifyList = data.classifyList.slice(0,8)
        // this.$store.commit('setClassifyList', data.classifyList)
        try {
          window.localStorage.setItem('homeClassifyList', JSON.stringify(data.classifyList))
        } catch (e) {
          console.log(e)
        }
      }
    },
    async onRefresh () {
      await this.getBannerInfo()
      this.schoolList = []
      this.pageNo = 1
      await this.getGoodsList()
      this.isLoading = false
    },
    goType (index, item) {
      this.$router.push(`/home/goods?index=${index}&classifyNo=${item.classifyNo || ''}`)
    },
    goSchoolDetail (item) {
      this.$router.push(`/goods/detail?id=${item.courseNo || ''}`)
    },
    // 处理滚动条滚到底部加载数据
    getScroll (event) {
      let nScrollHight = 0
      let nScrollTop = 0
      let nDivHight = event.target.offsetHeight // 视口的高度
      nScrollHight = event.target.scrollHeight // 滚动高度
      nScrollTop = event.target.scrollTop // 滚动最上面的距离
      if (nScrollTop + nDivHight + 10 >= nScrollHight) { // 解决不同分辨率下scrollTop的差异
        if (this.pageNo * this.pageSize <= this.total) {
          this.getGoodsList()
        }
      }
    },
    goSearch () {
      this.$router.push('/home/search')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './item-box.scss';
.page-box {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 5px;
  .search-box {
    width: 346px;
    height: 34px;
    background: #fff;
    border-radius: 17px;
    color: #8F92A9;
    font-size: 13px;
    line-height: 34px;
    text-align: center;
    position: relative;
    // left: 50%;
    // margin-left: -173px;
    // top: 24px;
    margin: 12px auto;
    z-index: 99;
    border: 1px solid #4D78FF;
    .icon {
      position: absolute;
      right: 12px;
      top: 6px;
      width: 20px;
      height: 20px;
      background: url(../../assets/img/hr-search.png) center center no-repeat;
      background-size: 100% 100%;
    }
  }
  .type-box {
    width: 100%;
    display: flex;
    position: relative;
    padding: 0 15px;
    margin-top: 6px;
    .type-item {
      flex: 1;
      margin-right: 10px;
      position: relative;
    }
    .type-item:last-child {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 65px;
    }
    .type-text-a {
      position: absolute;
      left: 6px;
      top: 8px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
    .type-text-b {
      position: absolute;
      left: 6px;
      bottom: 14px;
      color: #fff;
      font-size: 12px;
    }
  }
  .banner-box {
    width: 340px;
    height: 166px;
    position: relative;
    margin: 0 auto;
    .swiper {
      height: 100%;
      width: 100%;
      position: relative;
      text-align: center;
      line-height: 215px;
      .swiper-item {
        img {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
      .custom-indicator {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        height: 20px;
        line-height: 14px;
        cursor: pointer;
        .swiper-indicator {
          width: 10px;
          height: 3px;
          display: inline-block;
          margin: 0 3px;
          background: rgb(190,190,197);
          border-radius: 1.5px;
        }
        .active-indicator {
          background: #282734;
        }
      }
    }
  }

  .active-img {
    margin-top: 2px;
    min-height: 75px;
    padding: 0 16px;
    .van-image {
      width: 100%;
      height: 75px;
    }
  }

  .list-box {
    padding: 5px 0;
    .list-title {
      padding: 0 16px 0 28px;
      line-height: 30px;
      font-size: 16px;
      font-weight:bold;
      .title-logo {
        width: 7px;
        height: 22px;
        position: absolute;
        background-color: #7B96FF;
        border-radius: 5px;
        left: 16px;
        top: 9px;
      }
      .more-btn {
        position: absolute;
        right: 11px;
        top: 0;
        color: #9EA5AD;
        padding: 5px;
        font-size: 12px;
        justify-content: center;
        i {
          top: 1px;
        }
      }
    }
    // .list-in-box::before,
    // .list-in-box::after {
    //   content: "";
    //   flex-basis: 100%;
    //   width: 0;
    //   order: 2;
    // }
    .list-in-box {
      width: 100%;
      padding: 6px 6px 6px 16px;
    }
  }
}
</style>
